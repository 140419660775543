import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import localeAR from '@angular/common/locales/ar';
import localeEn from '@angular/common/locales/en';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {TokenInterceptor} from '@core/@http/interceptors/token.interceptor';
import {AnnouncementBarComponent} from '@core/components/announcement-bar/announcement-bar.component';
import {AuthNavBarComponent} from '@core/components/app-main-navbar/auth-nav-bar/auth-nav-bar.component';
import {GuestNavBarComponent} from '@core/components/app-main-navbar/guest-nav-bar/guest-nav-bar.component';
import {MainAppNavbarComponent} from '@core/components/app-main-navbar/main-navbar.component';
import {SideNavAuthComponent} from '@core/components/app-main-navbar/side-nav-auth/side-nav-auth.component';
import {SmallNavLinkComponent} from '@core/components/app-main-navbar/small-nav-link/small-nav-link.component';
import {DoTestBarComponent} from '@core/components/do-test-bar/do-test-bar.component';

import {FooterComponent} from '@core/components/footer/footer.component';
import {NextSessionBarComponent} from '@core/components/next-session-bar/next-session-bar.component';
import {GoToTopOfPageComponent} from '@core/components/top-of-page/top-of-page.component';
import {
  ChangeSessionUrlDialogueMoudle,
} from '@shared/modules/courses-utilities/components/change-session-url-dialouge/change-session-url-dialogue.moudle';
import {SharedModule} from '@shared/shared.module';
import {LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SocialLoginModule} from '@social-login/sociallogin.module';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from '@libs/social-login';
import {NewChatUiModule} from '@shared/modules/chat/new-chat-ui/new-chat-ui.module';
import {DefaultScheduleBarComponent} from '@core/components/default-schedule-bar/default-schedule-bar.component';
import {ConversationClubBarComponent} from '@core/components/conversation-club-bar/conversation-club-bar.component';
import {StudentVipTagComponent} from '@shared/stanndalones/student-vip-tag/student-vip-tag.component';
import {PackageTrialTagComponent} from '@shared/stanndalones/package-trial-tag/package-trial-tag.component';


registerLocaleData(localeEn);
registerLocaleData(localeAR);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FooterComponent,
    GoToTopOfPageComponent,
    SmallNavLinkComponent,
    SideNavAuthComponent,
    MainAppNavbarComponent,
    GuestNavBarComponent,
    AuthNavBarComponent,
    AnnouncementBarComponent,
    NextSessionBarComponent,
    DoTestBarComponent,
    DefaultScheduleBarComponent,
    ConversationClubBarComponent,
  ],
  imports: [
    SharedModule,
    ChangeSessionUrlDialogueMoudle,
    SocialLoginModule,
    NewChatUiModule,
    StudentVipTagComponent,
    PackageTrialTagComponent,
  ],
  exports: [
    MainAppNavbarComponent,
  ],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
    {provide: MAT_DATE_LOCALE, useValue: 'en'},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // oto google app
            provider: new GoogleLoginProvider(
              '225550522552-aoim90a2kqs4qlh28t9q0pu0gictmuus.apps.googleusercontent.com',
              {
                oneTapEnabled: false,
                scopes: 'profile https://www.googleapis.com/auth/calendar',
                prompt: 'select_account'
              }
            )
            // my google app
            // provider: new GoogleLoginProvider(
            //   '1007456682672-3dkbpi4ai7rg9td4b3gcaojo1eqt7muh.apps.googleusercontent.com'
            // )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('303095010352055')
          }
        ],
        onError: (err) => {
          console.error('SocialAuthServiceConfig ', err);
        }
      } as SocialAuthServiceConfig,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
