<section *ngIf="!loading && nextSessionData">
  <!-- case have sessions and have booked sessions -->
  <div *ngIf="nextSessionData!.unscheduled_sessions >= 0
             && nextSessionData!.totalLength > 0
             && nextSessionData?.next_session !== null"
       [class.cancelled__btn]="
            nextSessionData?.next_session!.is_started &&
            !(nextSessionData?.next_session?.date_remaining_ms | timesDiff:type:isEnteredBefore:serverTime).status"
       [ngClass]="{'active__btn neon__shadow':nextSessionData?.next_session?.is_started &&
            (nextSessionData?.next_session?.date_remaining_ms | timesDiff:type:isEnteredBefore:serverTime).status}"
       class="next__session__container active__btn">
    <!-- IF session Not Started   -->
    <button
      *ngIf="nextSessionData?.next_session !== null && !nextSessionData?.next_session!.is_started"
      class="start__live-btn cursorDisabled" disabled>
      <ng-container *appRole="'teacher'">
        <app-student-vip-tag
          *ngIf="nextSessionData!.next_session!.students[0] && nextSessionData!.next_session!.students[0].type"
          [type]="nextSessionData!.next_session!.students[0].type"
          class="mx-2"/>
        <app-package-trial-tag *ngIf="nextSessionData!.next_session!.is_trial" class="mx-2"/>
      </ng-container>
      <span>{{ message }}</span>
    </button>
    <!-- IF session Started   -->
    <button (click)="StartSession(nextSessionData?.next_session)"
            *ngIf="nextSessionData?.next_session !== null && nextSessionData?.next_session!.is_started"
            [class.cursorDisabled]="!nextSessionData?.next_session!.is_started ||
            !(nextSessionData?.next_session?.date_remaining_ms | timesDiff:type:isEnteredBefore:serverTime).status"
            [class.spinner--small]="startLiveLoading"
            [disabled]="!nextSessionData?.next_session!.is_started ||
            !(nextSessionData?.next_session?.date_remaining_ms | timesDiff:type:isEnteredBefore:serverTime).status"
            class="start__live-btn">
      <ng-container *appRole="'teacher'">
        <app-student-vip-tag
          *ngIf="nextSessionData!.next_session!.students[0] && nextSessionData!.next_session!.students[0].type"
          [type]="nextSessionData!.next_session!.students[0].type"
          class="mx-2"/>
        <!--        <app-student-vip-tag-->
        <!--          *ngIf="nextSessionData!.next_session!.students[0] && nextSessionData!.next_session!.students[0].is_vip"-->
        <!--        class="mx-2"/>-->
        <app-package-trial-tag *ngIf="nextSessionData!.next_session!.is_trial" class="mx-2"/>
      </ng-container>
      <span>{{ (nextSessionData?.next_session?.date_remaining_ms | timesDiff:type:isEnteredBefore:serverTime).message }}</span>
    </button>
  </div>

  <!-- case have sessions but not booked any one so book your next session -->
  <div *ngIf="nextSessionData!.unscheduled_sessions >= 0
                                   && nextSessionData!.totalLength > 0
                                   && nextSessionData?.next_session === null" class="semi__action">
    <a *appRole="'student'" [routerLink]="['/my-account/my-courses' | localize]" class="semi__action__active">
      {{ 'sentences.book_session' |  translate }}
    </a>
    <a *appRole="'teacher'" class="semi__action__disabled" disabled="true">
      {{ 'no_data.sessions' | translate }}
    </a>
  </div>

  <!-- case have no sessions so buy a course -->
  <div *ngIf="nextSessionData?.totalLength === 0 && nextSessionData?.next_session === null" class="semi__action">
    <a *appRole="'student'" [routerLink]="['/courses' | localize]"
       class="semi__action__active">{{ 'actions.buy_new_course'|translate }}</a>
    <a *appRole="'teacher'" class="semi__action__disabled" disabled="true">{{ 'no_data.sessions'|translate }}</a>
  </div>

</section>
