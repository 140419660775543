<svg *ngIf="type === 'vip'" fill="#fcb040" id="Layer_1" version="1.1" viewBox="0 0 512.001 512.001"
     xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g>
	<g>
		<polygon points="214.05,182.273 175.855,291.388 137.651,182.273 110.174,182.273 162.15,327.186 163.072,329.729
			188.629,329.729 239.727,187.41 241.553,182.273 		"/>
	</g>
</g>
  <g>
	<g>
		<rect height="147.447" width="26.999" x="252.203" y="182.273"/>
	</g>
</g>
  <g>
	<g>
		<path d="M396.544,207.275c-2.97-5.598-7.168-10.351-12.467-14.123c-5.171-3.661-11.324-6.434-18.278-8.235
			c-6.775-1.749-14.199-2.637-22.05-2.637h-37.623v147.448h27v-55.202h5.803c8.576,0,16.7-1.067,24.149-3.174
			c7.526-2.15,14.174-5.274,19.746-9.301c5.675-4.087,10.197-9.139,13.423-15.027c3.226-5.939,4.872-12.663,4.872-19.985
			C401.118,219.597,399.565,212.95,396.544,207.275z M371.755,236.971c-1.579,2.935-3.874,5.504-6.827,7.612
			c-3.046,2.176-6.724,3.84-10.948,4.975c-4.352,1.178-9.208,1.766-14.43,1.766h-6.426v-45.85h10.001
			c4.446,0,8.721,0.435,12.723,1.297c3.772,0.785,7.074,2.091,9.847,3.866c2.628,1.715,4.727,3.874,6.204,6.46
			c1.476,2.56,2.227,5.828,2.227,9.728C374.127,230.639,373.325,234.053,371.755,236.971z"/>
	</g>
</g>
  <g>
	<g>
		<path d="M504.508,237.901l-59.981-59.981v-84.83c0-14.14-11.46-25.6-25.6-25.6h-84.838L274.108,7.51
			C269.303,2.697,262.793,0.001,256,0.001s-13.303,2.697-18.099,7.501L177.92,67.482H93.082c-14.14,0-25.6,11.46-25.6,25.6v84.83
			L7.501,237.893C-2.5,247.894-2.5,264.099,7.501,274.1l59.981,59.981v84.83c0,14.14,11.46,25.6,25.6,25.6h84.838l59.981,59.981
			c4.796,4.813,11.307,7.509,18.099,7.509s13.303-2.697,18.099-7.501l59.981-59.981h84.838c14.14,0,25.6-11.46,25.6-25.6v-84.83
			l59.981-59.981C514.5,264.107,514.5,247.894,504.508,237.901z M418.918,323.482v95.437h-95.437L256,486.401l-67.482-67.482H93.082
			v-95.437L25.6,256.001l67.482-67.482V93.082h95.437L256,25.601l67.482,67.482h95.437v95.437l67.482,67.482L418.918,323.482z"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="256" cy="76.801" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="256" cy="435.201" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="129.28" cy="129.281" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="382.711" cy="382.712" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="382.711" cy="129.281" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="129.28" cy="382.712" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="435.2" cy="256.001" r="12.8"/>
	</g>
</g>
  <g>
	<g>
		<circle cx="76.8" cy="256.001" r="12.8"/>
	</g>
</g>
</svg>

<svg *ngIf="type === 'premium'" id="Layer_1" style="enable-background:new 0 0 500 500;" version="1.1"
     viewBox="0 0 500 500" x="0px"
     xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
<style type="text/css">
	.st0 {
    fill: none;
    stroke: #1E003A;
    stroke-width: 5;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: #DD9414;
  }

  .st2 {
    font-family: 'Poppins-Bold';
  }

  .st3 {
    font-size: 109.8118px;
  }
</style>
  <g>
	<path class="st0" d="M242.93,474.67l-217.6-217.6c-3.89-3.89-3.89-10.25,0-14.14l217.6-217.6c3.89-3.89,10.25-3.89,14.14,0
		l217.6,217.6c3.89,3.89,3.89,10.25,0,14.14l-217.6,217.6C253.18,478.56,246.82,478.56,242.93,474.67z"/>
    <line class="st0" x1="81.69" x2="420.34" y1="188.19" y2="188.19"/>
    <line class="st0" x1="81.09" x2="418.61" y1="311.81" y2="311.81"/>
    <text class="st1 st2 st3" transform="matrix(1 0 0 1 109.7715 290.749)">PREM</text>
</g>
</svg>

