import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StudentPackageType} from '@core/@data/UsersData/Account';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-student-vip-tag',
  templateUrl: './student-vip-tag.component.html',
  styleUrls: ['./student-vip-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf
  ],
  standalone: true
})
export class StudentVipTagComponent {
  @Input() type: StudentPackageType = 'vip';
}
