import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiResponse} from '@core/@data/Api/Api';
import {NewSemiInsights} from '@core/@data/CoursesData/StudentInsights';
import {NewStudentCoursesStats, StudentSession} from '@core/@data/New/NEW-Student';
import {NewTeacherCoursesStats, TeacherSession} from '@core/@data/New/NEW-Teacher';
import {AuthService} from '@core/@http/services/auth.service';
import {GeneralService} from '@core/@http/services/general.service';
import {SessionsService} from '@core/@http/services/sessions.service';
import {
  ChangeSessionUrlDialogueService,
} from '@shared/modules/courses-utilities/components/change-session-url-dialouge/change-session-url-dialogue.service';

import {ToasterService} from '@shared/services/toastr.service';
import {Observable, Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment as env} from '../../../../environments/environment';


export const HALF_MINUTE = 30000;
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const MILE_SECOND_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;

// calculateCounterValues(time: number): string {
//   const {days, hours, minutes, seconds} = this.calculateTimeComponents(time);
//   console.log('time ', time, '    ', days, ' ', hours, ' ', minutes, ' ', seconds);
//   // Check if time is positive
//   if (time > 0) {
//     console.log('777777777777777');
//
//     return this.getFormattedTime(days, hours, minutes, seconds);
//   }
//
//   // Check if time is zero
//   if (Math.abs(time) < 0.001) {
//     console.log(' if (time === 0) { ', Math.abs(time));
//     return this.reloadPageAndReturnEmpty() + 'Next Session is now';
//   }
//
//   // Check if time is negative
//   if (time < 0) {
//     console.log('6666666666666');
//
//     if (this.nextSessionData?.next_session === null) {
//       console.log('5555555555');
//
//       this.CounterUnsubscribe();
//       return '';
//     }
//     // Check specific conditions for reloading page
//     if (
//       (this.type === 'student' && days === -1 && hours === 23 && minutes === 39 && seconds === 58) ||
//       (this.type === 'teacher' && days === -1 && hours === 23 && minutes === 44 && seconds === 58) ||
//       (days === -1 && hours === 22 && minutes === 59 && seconds === 30)
//     ) {
//       console.log('11111111111111');
//       return this.reloadPageAndReturnEmpty();
//     }
//     console.log('3333333333');
//     return '';
//   }
//   console.log('2222222222222222');
//   return '';
// }
//
// reloadPageAndReturnEmpty(): string {
//   this.generalService.reloadPage();
//   return '';
// }
//
// calculateTimeComponents(time: number): { days: number; hours: number; minutes: number; seconds: number } {
//   let temp = time / 1000;
//   const days = Math.floor(temp / SECONDS_IN_DAY);
//   temp -= days * SECONDS_IN_DAY;
//   const hours = Math.floor(temp / SECONDS_IN_HOUR) % 24;
//   temp -= hours * SECONDS_IN_HOUR;
//   const minutes = Math.floor(temp / SECONDS_IN_MINUTE) % 60;
//   temp -= minutes * SECONDS_IN_MINUTE;
//   const seconds = Math.floor(temp % SECONDS_IN_MINUTE);
//
//   return {days, hours, minutes, seconds};
// }
//
// getFormattedTime(days: number, hours: number, minutes: number, seconds: number): string {
//   return `${this.generalService.getTranslation('sentences.next_session_after')} ${days} ${this.generalService.getTranslation('words.day')}, ${hours} ${this.generalService.getTranslation('words.hour')}, ${minutes} ${this.generalService.getTranslation('words.minutes')}, ${seconds} ${this.generalService.getTranslation('words.seconds')}`;
// }


@Component({
  selector: 'app-next-session-bar',
  templateUrl: './next-session-bar.component.html',
  styleUrls: ['./next-session-bar.component.scss'],
})
export class NextSessionBarComponent implements OnInit, OnDestroy {
  type = '';

  nextSessionData: NewSemiInsights | null = null;
  subscription: Subscription | null = null;
  loading = true;
  startLiveLoading = false;
  isEnteredBefore: string | null | undefined = null;
  time = 0;
  message!: string;
  serverTime = 0;
  private sessionTime = 0;
  private counter$!: Observable<number>;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private sessionsService: SessionsService,
    public datePipe: DatePipe,
    private generalService: GeneralService,
    private changeSessionUrlDialogueService: ChangeSessionUrlDialogueService,
    private toasterService: ToasterService,
  ) {
    this.authService.userDataBehaviorSubject.subscribe((res) => {
      if (res !== null) {
        this.type = res?.type;
        this.GetSessionData();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

  StartCounting(): void {
    this.CounterUnsubscribe();
    this.sessionTime = new Date(this.nextSessionData?.next_session?.date_utc?.starts_at ?? '').getTime() + HALF_MINUTE;
    // console.log(this.sessionTime, this.serverTime);
    const time = (this.nextSessionData?.next_session?.date_remaining_ms as number) + HALF_MINUTE;
    this.counter$ = timer(0, 1000).pipe(
      map((tick) => {
        // console.log('tick ', tick);
        return time - (tick * 1000);
      }));
    this.subscription = this.counter$.subscribe((x) => {
      // console.log('x ', x);
      this.time = x;
      this.message = this.CalculateCounterValues(x);
    });
  }

  CounterUnsubscribe(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

  CalculateCounterValues(time: number): string {
    // console.log(time);
    let temp = time / 1000;
    let days, hours, minutes, seconds;
    days = Math.floor(temp / SECONDS_IN_DAY);
    temp -= days * SECONDS_IN_DAY;
    hours = Math.floor(temp / SECONDS_IN_HOUR) % 24;
    temp -= hours * SECONDS_IN_HOUR;
    minutes = Math.floor(temp / SECONDS_IN_MINUTE) % SECONDS_IN_MINUTE;
    temp -= minutes * SECONDS_IN_MINUTE;
    seconds = Math.floor(temp % SECONDS_IN_MINUTE);
    // console.log(time, '    ', days, ' ', hours, ' ', minutes, ' ', seconds);
    if (time > 0) {
      return `${this.generalService.getTranslation('sentences.next_session_after')} ${days} ${this.generalService.getTranslation('words.day')}, ${hours} ${this.generalService.getTranslation('words.hour')}, ${minutes} ${this.generalService.getTranslation('words.minutes')}, ${seconds} ${this.generalService.getTranslation('words.seconds')}`;
    }
    if (time === 0) {
      this.generalService.reloadPage();
      return `Next Session is now`;
    }
    if (time < 0) {
      // console.log('if (time < 0)');
      if (this.nextSessionData?.next_session === null) {
        this.CounterUnsubscribe();
        return '';
      } else {
        if (this.type === 'student' && days === -1 && hours === 23 && minutes === 39 && seconds === 58) {
          this.generalService.reloadPage();
          return '';
        }
        if (this.type === 'teacher' && days === -1 && hours === 23 && minutes === 44 && seconds === 58) {
          this.generalService.reloadPage();
          return '';
        }
        if (days === -1 && hours === 22 && minutes === 59 && seconds === 30) {
          this.generalService.reloadPage();
          return '';
        }
        return '';
      }
    }
    return '';
  }

  GetStudentCoursesStatistics(): Observable<NewSemiInsights> {
    return this.http.get<ApiResponse<NewStudentCoursesStats>>(`${env.ApiUrl}/student/courses/statistics`).pipe(
      map((data) => {
        // console.log(data);
        this.isEnteredBefore = data?.data?.general?.next_session?.student_enters_at;
        return {
          next_session: data?.data?.general?.next_session,
          unscheduled_sessions: data?.data?.general?.statistics?.unscheduled_sessions,
          totalLength: data?.data?.general?.statistics?.total_packages,
        };
      }),
    );
  }

  GetTeacherCoursesStatistics(): Observable<NewSemiInsights> {
    return this.http.get<ApiResponse<NewTeacherCoursesStats>>(`${env.ApiUrl}/teacher/courses/statistics`).pipe(
      map((data) => {
        this.isEnteredBefore = data?.data?.general?.next_session?.teacher_enters_at;
        return {
          next_session: data?.data?.general?.next_session,
          unscheduled_sessions: data?.data?.general?.statistics?.unscheduled_sessions,
          totalLength: data?.data?.general?.statistics?.total_packages,
        };
      }),
    );
  }

  GetSessionData(): void {
    if (this.type && this.type === 'student') {
      this.GetStudentCoursesStatistics().subscribe((res) => {
        this.nextSessionData = res;
        // console.log('dd', this.nextSessionData?.unscheduled_sessions >= 0);
        // console.log('dd', this.nextSessionData?.totalLength > 0);
        // console.log('dd', this.nextSessionData?.next_session !== null);
        if (this.nextSessionData?.unscheduled_sessions >= 0
          && this.nextSessionData?.totalLength > 0
          && this.nextSessionData?.next_session !== null) {
          this.StartCounting();
        }
        this.loading = false;
      });
    }
    if (this.type && this.type === 'teacher') {
      this.GetTeacherCoursesStatistics().subscribe((res) => {
        this.nextSessionData = res;
        if (this.nextSessionData?.unscheduled_sessions >= 0
          && this.nextSessionData?.totalLength > 0
          && this.nextSessionData?.next_session !== null) {
          this.StartCounting();
        }
        this.loading = false;
      });
    }
  }

  StartSession(session: StudentSession | TeacherSession | null | undefined): void {
    if (session !== null && session) {
      this.startLiveLoading = true;
      this.sessionsService.JoinSession(this.type ?? '', session?.id).subscribe((res) => {
        if (res.data.type === 'external') {
          this.sessionsService.StartSessionLiveNow(res.data.url);
          if (this.isEnteredBefore === null) {
            this.generalService.reloadPage();
          }
          this.startLiveLoading = false;
        } else {
          this.startLiveLoading = false;
        }
      }, (error) => {
        console.log(error);
        error?.error?.data?.errors?.forEach((err: string) => {
          this.toasterService.showFail(err);
          if (this.type && this.type === 'teacher' && err.includes('no session url added yet')) {
            this.changeSessionUrlDialogueService.open({sessionId: session.id});
          }
        });
        this.startLiveLoading = false;
      });
    } else {
      this.startLiveLoading = false;
    }
  }
}
