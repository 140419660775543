import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-package-trial-tag',
  template: `
    <p [class.next-session]="isNextSession">
      {{ 'words.trial' |  translate }}
    </p>
  `,
  styles: [
    `
      p {
        margin: 0;
        padding: 0.3rem 0.4rem;
        background: var(--oto-blue);
        color: var(--color-white);
        width: fit-content;
        border-radius: 8px;

        &.next-session {
          padding: 0.15rem 0.2rem;
          background: var(--color-white);
          color: var(--oto-blue);
          border-radius: 8px;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule
  ],
  standalone: true
})
export class PackageTrialTagComponent {
  @Input() isNextSession = false;
}
